<template>
  <FDropdown
    class="select-columns"
    block-class="select-columns-popper"
    placement="bottom-end"
  >
    <template #title>
      <span class="select-columns__label filter-item">
        <AppIcon
          name="settings"
          class="select-columns__label-icon"
          size="18px"
          is-img-tag
        />
        {{ t('component.table.column', 3) }}
      </span>
    </template>
    <template #content>
      <template v-for="column in filteredColumns" :key="column.name">
        <DropdownItem
          v-if="!column.nonSelectable"
          size="small"
          class="checkbox-hover"
          :class="{
            'is-permanent': column.isPermanent,
          }"
          no-border
          @mouseup="onVisibilityChange(column)"
          @click.stop
        >
          <FCheckbox
            :key="column.name"
            :disabled="column.isPermanent"
            :model-value="isChecked(column.name)"
            :label="getLabel(column.name)"
          />
        </DropdownItem>
      </template>
    </template>
  </FDropdown>
</template>

<script>
import { computed } from 'vue';

import DropdownItem from '@/components/ElementUI/DropdownItem.vue';

import { emitter } from '@/composables/useBus';
import { i18n } from '@/plugins/localization';
import { updateTableColumn, useTablesStore } from '@/store';

export default {
  name: 'SelectColumns',
  components: {
    DropdownItem,
  },
  props: {
    moduleName: {
      type: String,
      default: '',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    status: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const onVisibilityChange = (column) => {
      if (!column.isPermanent) {
        const value = !isChecked(column.name);

        updateTableColumn({
          moduleName: props.moduleName,
          columnName: column.name,
          field: 'isShow',
          value,
        });
        emitter.emit('toggle-column', {
          columnName: column.name,
          isShow: value,
        });
      }
    };

    const tablesStore = useTablesStore();
    const isChecked = (columnName) => tablesStore.getTableColumns({
      moduleName: props.moduleName,
      columnName,
      field: 'isShow',
    });

    const { t } = i18n.global;
    const getLabel = (columnName) => {
      const title = props.columns.find((col) => col.name === columnName).title;
      return title || t(`${props.moduleName}.table.columns.${columnName}`);
    };

    const filteredColumns = computed(() => {
      if (props.status !== 'PRUNED') {
        return props.columns.filter((column) => column.name !== 'prunedOn');
      }

      return props.columns;
    });

    return {
      onVisibilityChange,
      isChecked,

      getLabel,
      filteredColumns,
    };
  },
};
</script>

<style scoped lang="scss">
.select-columns {
  display: flex;
  align-items: center;

  :deep(.f-dropdown--title) {
    padding: 0;
  }

  &__label {
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 1.2;
    padding: 0 10px;

    &-icon {
      opacity: 0.4;
      margin-right: 8px;
    }
  }
}

:deep(.el-checkbox__label) {
  text-transform: capitalize;
}

.is-permanent {
  cursor: not-allowed;
}
</style>

<template>
  <FDropdown
    class="select-show-on-page"
    block-class="select-show-on-page-popper"
  >
    <template #title>
      <span class="select-show-on-page__label filter-item">
        {{ t('component.table.itemsPerPage') }}:
        <AppText
          color="black"
          ml="8px"
        >{{ perPage }}</AppText>
      </span>
    </template>
    <template #content>
      <ContainerWrapper
        background-color="white"
        class="select-show-on-page__body"
      >
        <DropdownItem
          v-for="item in pageSettings"
          :key="item"
          size="small"
          no-border
          @click="changeItemsPerPage(item)"
        >
          <AppText>
            {{ item }}
          </AppText>
        </DropdownItem>
      </ContainerWrapper>
    </template>
  </FDropdown>
</template>

<script>
import { reactive } from 'vue';

import ContainerWrapper from '@/components/Containers/ContainerWrapper.vue';
import DropdownItem from '@/components/ElementUI/DropdownItem.vue';

import { useModel } from '@/composables/useModel';

export default {
  name: 'SelectShowOnPage',
  components: {
    ContainerWrapper,
    DropdownItem,
  },
  props: {
    // eslint-disable-next-line
    modelValue: {
      required: true,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { value: perPage } = useModel(props, emit);

    const pageSettings = reactive([25, 50, 100]);

    const changeItemsPerPage = (item) => {
      perPage.value = item;
    };

    return {
      perPage,
      pageSettings,
      changeItemsPerPage,
    };
  },
};
</script>

<style scoped lang="scss">
:global(.select-show-on-page-popper) {
  width: 150px;
}

.select-show-on-page {
  :deep(.f-dropdown--title) {
    padding: 0;
  }

  &__label {
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 1.2;
    white-space: pre;
    padding: 0 10px;
  }
  &__body {
    min-width: 88px;
  }
}
</style>
